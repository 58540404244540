import {
  GemaMemberResponse,
  MemberStatus,
  DelegateStatus,
  OccupationalGroup,
  AttendanceType,
  SideEventAttendance,
} from "../@types";

export class GemaMember implements GemaMemberResponse {
  private constructor(
    public serialNumber: number,
    public bpid: string,
    public firstName: string,
    public lastName: string,
    public memberNumber: string,
    public memberStatus: MemberStatus | null,
    public delegate: DelegateStatus | null,
    public group: OccupationalGroup | null,
    public email: string | null,
    public birthDate: string | null,
    public attendanceType: AttendanceType | null,
    public sideEventAttendance: SideEventAttendance | null,
    public publisherName: string | null,
    public kaStreet: string | null,
    public kaPlz: string | null,
    public kaCity: string | null,
    public kaCountryIso: string | null,
    public kaEpost: string | null,
    public portalPdf: string | null,
    public publisherWithoutVotingPermission: boolean,
    public kooptiert: boolean,
    public rn: boolean,
    public portalOpened: boolean,
    public digitalVirtuos: boolean
  ) {}

  // This is an example, we want to set the unique document id to null and maybe reset registrations etc...
  public copyAsNew(member?: GemaMemberResponse): GemaMember {
    if (member) {
      return GemaMember.fromGemaMemberReponse(member).copyAsNew();
    }

    return new GemaMember(
      this.serialNumber,
      this.bpid,
      this.firstName,
      this.lastName,
      this.memberNumber,
      this.memberStatus,
      this.delegate,
      this.group,
      this.email,
      this.birthDate,
      this.attendanceType,
      this.sideEventAttendance,
      this.publisherName,
      this.kaStreet,
      this.kaPlz,
      this.kaCity,
      this.kaCountryIso,
      this.kaEpost,
      this.portalPdf,
      this.publisherWithoutVotingPermission,
      this.kooptiert,
      this.rn,
      this.portalOpened,
      this.digitalVirtuos
    );
  }

  public static fromGemaMemberReponse(member: GemaMemberResponse): GemaMember {
    return new GemaMember(
      member.serialNumber,
      member.bpid,
      member.firstName,
      member.lastName,
      member.memberNumber,
      member.memberStatus,
      member.delegate,
      member.group,
      member.email,
      member.birthDate,
      member.attendanceType,
      member.sideEventAttendance,
      member.publisherName,
      member.kaStreet,
      member.kaPlz,
      member.kaCity,
      member.kaCountryIso,
      member.kaEpost,
      member.portalPdf,
      member.publisherWithoutVotingPermission,
      member.kooptiert,
      member.rn,
      member.portalOpened,
      member.digitalVirtuos
    );
  }
}
