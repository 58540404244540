import { GemaMember } from ".";
import {
  RegistrationInfo,
  RegistrationResponse,
  RegistrationStatus,
} from "../@types";

export class Registration implements RegistrationResponse {
  public registration: RegistrationInfo;
  public member: GemaMember;

  constructor(registration: RegistrationResponse) {
    this.registration = registration.registration;
    this.member = GemaMember.fromGemaMemberReponse(registration.member);
  }

  public static newRegistration(member: GemaMember): Registration {
    const now = Date.now();
    return new Registration({
      registration: {
        id: "",
        userId: "",
        attendanceType: null,
        attendanceTypePending: null,
        days: null,
        status: RegistrationStatus.CONFIRMED,
        representation: null,
        createdTimestamp: now.toString(),
        lastEditedTimestamp: now.toString(),
        termsAcceptedTimestamp: null,
        lastEditor: null,
      },
      member: member,
    });
  }
}
