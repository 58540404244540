import { http } from "@/services/api.service";
import { AxiosInstance } from "axios";
import {
  GemaMemberResponse,
  RegistrationExportResponse,
  RegistrationResponse,
  ResetPasswordResponse,
} from "../@types";
import { GemaMember, Registration } from "../models";

class RegistrationService {
  private axios: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axios = axiosInstance;
  }

  // Exporting
  async export(
    query: SearchRegistrationsQueryParams,
    type: ExportType
  ): Promise<void> {
    let csv = "";
    if (type !== ExportType.SIDE_EVENTS) {
      csv = await this.axios
        .post<RegistrationExportResponse>(`/registrations/export`, {
          ...query,
          searchQuery: query.searchQuery || "",
          sortBy: query.sortBy || "id",
          page: query.page && query.page >= 0 ? Math.max(0, query.page - 1) : 0,
          exportDelimiter: ",",
        })
        .then((res) => res.data.csvString);
    } else {
      csv = await this.axios
        .get<RegistrationExportResponse>("/export/side-event-attendance")
        .then((res) => res.data.csvString);
    }
    if (csv) {
      let filename: string;
      // YYYY-MM-DD HH:MM
      const dateString = new Date()
        .toISOString()
        .substring(0, 16)
        .replace("T", " ");

      switch (type) {
        case ExportType.ALL:
          filename = `${dateString} export all`;
          break;
        case ExportType.PAGE:
          filename = `${dateString} export page`;
          break;
        case ExportType.QUERY:
          filename = `${dateString} export query`;
          break;
        case ExportType.SIDE_EVENTS:
          filename = `${dateString} export side events`;
          break;
        default:
          filename = `${dateString} export`;
          break;
      }

      download(csv, `${filename}.csv`, "text/csv;encoding:utf-8");
    }
  }

  async exportRepresentativeRegistrations(
    representativeUserId: string,
    representativeName: string
  ): Promise<void> {
    const res: RegistrationExportResponse = await this.axios
      .get(`/registrations/export/representation`, {
        params: {
          representativeUserId: representativeUserId,
        },
      })
      .then((res) => res.data);

    if (res.csvString) {
      // YYYY-MM-DD HH:MM
      const dateString = new Date()
        .toISOString()
        .substring(0, 16)
        .replace("T", " ");

      download(
        res.csvString,
        `${dateString}_${representativeName}.csv`,
        "text/csv;encoding:utf-8"
      );
    }
  }

  // Representatives

  async getRegsistrationsByMemberNumberOrUserId(
    id: string
  ): Promise<RegistrationResponse[]> {
    return this.axios
      .get(`/registrations/represented-by/${id}`)
      .then((res) => res.data);
  }

  async getRegistrations(
    page = 1,
    perPage = 25
  ): Promise<RegistrationResponse[]> {
    return this.axios
      .get(`/registrations`, {
        params: {
          resultsPerPage: perPage,
          page: Math.max(0, page - 1),
        },
      })
      .then((res) => res.data);
  }

  async searchRegistrations<T>(
    args: SearchRegistrationsQueryParams
  ): Promise<SearchResponse<T>> {
    return this.axios
      .post(`/registrations/search`, {
        ...args,
        searchQuery: args.searchQuery || "",
        sortBy: args.sortBy || "id",
        // order: args.order || "ASC",
        page: args.page && args.page >= 0 ? Math.max(0, args.page - 1) : 0,
        resultsPerPage:
          args.resultsPerPage && args.resultsPerPage >= 1
            ? args.resultsPerPage
            : 10,
      })
      .then((res) => res.data);
  }

  async getbyId(id: string): Promise<Registration> {
    return this.axios.get(`/registrations/${id}`).then((res) => res.data);
  }

  async create(registration: Registration): Promise<Registration> {
    return this.axios
      .post(`/registrations`, registration)
      .then((res) => res.data);
  }

  async patch(registration: Registration): Promise<Registration> {
    return this.axios
      .patch(`/registrations`, registration)
      .then((res) => res.data);
  }

  async getGemaMemberById(memberId: string): Promise<GemaMember> {
    return this.axios
      .get<GemaMemberResponse>(`/gema-members/${memberId}`)
      .then((res) => GemaMember.fromGemaMemberReponse(res.data));
  }

  async resendMail(registrationId: string): Promise<unknown> {
    return this.axios
      .post(`/registrations/${registrationId}/resend-email`)
      .then((res) => res.data);
  }

  async resetMemberPassword(
    memberId: string,
    masterPassword: string
  ): Promise<ResetPasswordResponse> {
    return this.axios
      .post(`/gema-members/${memberId}/reset-password`, masterPassword, {
        headers: {
          "content-type": "text/plain",
        },
      })
      .then((res) => res.data);
  }
}

export default new RegistrationService(http);

export interface SearchRegistrationsQueryParams {
  searchQuery: string;
  filters: SearchFilter[];
  sortBy?: string;
  order?: "ASC" | "DESC";
  page?: number;
  resultsPerPage?: number;
}

export interface SearchFilter {
  key: string;
  value: string;
  fieldType?: "STRING" | "BOOLEAN";
}

export interface SearchResponse<T> {
  results: T[];
  query: string;
  totalCount: number;
}

export enum ExportType {
  ALL = "ALL",
  PAGE = "PAGE",
  QUERY = "QUERY",
  SIDE_EVENTS = "SIDE_EVENTS",
}

function download(content: string, fileName: string, mimeType: string) {
  const a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";

  if (URL && "download" in a) {
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href =
      "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
  }
}
